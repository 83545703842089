import React from 'react'

const Header = () => {
    return (
        <div className='header'>
            <div className="single-view-header flex items-center mb-5" >
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className='flex justify-center items-center'>
                                <h3 className='font-outfit text-white text-center'>Off - Plan Projects</h3>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
