import React from 'react'
import Header from './Header/Header'
import Option from './Option/Option'
import Properties from './Properties/Properties';
import { Helmet } from 'react-helmet';


const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Latest Off Plan Projects - Developments in Dubai, Sharjah, Abu Dhabi</title>
        <meta name="description" content="With us at EZhomes, you will get all the necessary information related to latest off Plan projects- Development in Dubai, UAE. Based on your specification, you can find all the important details related to New Launches, Under- Construction and Ready to Move properties." />
        <meta name="keywords" content="Latest, Off Plan Projects, Latest Offplan Projects, Developments in Dubai, EZhomes Properties, New Off Plan Project, Upcoming Off Plan Projects, New Launch Off Plan Projects, Dubai Properties Projects, Dubai Real Estate, Real Estate Projects in Dubai, Real Estate Projects in UAE, EZhomes Real Estate, Real Estate Information, Dubai Developers, Dubai Communities, New Launches, Under Constructions, Ready to Move, Latest Off Plan Projects in Dubai, Latest Off Plan Projects in Sharjah, Latest Off Plan Projects in Abu Dhabi, Latest Properties in Dubai, New Launch Properties, New Launch, Dubai, Sharjah, Abu Dhabi" />
        <meta property="og:title" content="Latest Off Plan Projects - Developments in Dubai, Sharjah, Abu Dhabi" />
        <meta property="og:description" content="With us at EZhomes, you will get all the necessary information related to latest off Plan projects- Development in Dubai, UAE. Based on your specification, you can find all the important details related to New Launches, Under- Construction and Ready to Move properties." />
        <meta property="og:url" content="https://www.off-plan.ezhomesuae.com/" />
        <meta property="og:type" content="https://www.ezhomesproperties.com/" />
      </Helmet>
      <Header />
      <Option />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Properties />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
