import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Pages/Home/Home';
import Header from './Components/Common/Header/Header';
import Footer from './Components/Common/Footer/Footer';
import "./App.css";
import Developer from './Components/Pages/Developer/Developer';
import Community from './Components/Pages/Community/Community';
import SubDeveloper from './Components/Pages/Developer/SubPage/SubDeveloper';
import SubCommunity from './Components/Pages/Community/SubPage/SubCommunity';
import AddCom from './Components/Add/AddCom';
import Add from './Components/Add/Add';


const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/developer' element={<Developer />} />
        <Route path='/developer/:id' element={<SubDeveloper />} />
        <Route path='/community' element={<Community />} />
        <Route path='/community/:id' element={<SubCommunity />} />
        <Route path='/config/add-property' element={<Add/>}/>
        <Route path='/config/add-community' element={<AddCom/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
