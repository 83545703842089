import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Developer = () => {

    const [developerList, setDeveloperList] = useState([]);
    const [dev, setDev] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState(''); // Add a state for search input
    const [sortOrder, setSortOrder] = useState('newest');

    function getDeveloperCount(developers) {
        const developerCount = {};

        developers.forEach((developer) => {
            // If the developer already exists in the object, increment the count
            if (developerCount[developer]) {
                developerCount[developer] += 1;
            } else {
                // Otherwise, set the count to 1
                developerCount[developer] = 1;
            }
        });

        // Convert the object to an array of objects
        const result = Object.keys(developerCount).map((developer) => ({
            developername: developer,
            projects: developerCount[developer],
        }));
        setDev(result);


        return result;
    }

    const fetchDevelopers = async () => {
        try {
            const response = await axios.get('https://off-plan.onrender.com/api/developers');
            const responseProperty = await axios.get('https://off-plan.onrender.com/properties');
            if (response?.status === 200) {
                setIsLoading(false);
                const data = [...response?.data].sort(() => Math.random() - 0.5);
                setDeveloperList(data);
                const developers = responseProperty?.data?.map((ele) => ele?.developer)
                getDeveloperCount(developers)
            } else {
                setIsLoading(true)
            }
        } catch (error) {
            console.error("Error fetching the properties:", error);
        }
    };

    useEffect(() => {
        fetchDevelopers();
    }, []);

    const handleSearch = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSort = (e) => {
        setSortOrder(e.target.value);
    };

    const filteredDeveloper = developerList.filter((property) => {
        const title = property.name.toLowerCase();
        const searchValue = searchInput.toLowerCase();
        return title.includes(searchValue);
    });

    const sortedDeveloper = filteredDeveloper.sort((a, b) => {
        switch (sortOrder) {
            case 'newest':
                return new Date(b.createdAt) - new Date(a.createdAt);
            case 'oldest':
                return new Date(a.createdAt) - new Date(b.createdAt);
            default:
                return 0;
        }
    });

    return (
        <div>
            <Helmet>
                <title>Real Estate Developers – Dubai, Sharjah, Abu Dhabi | International</title>
                <meta name="description" content="Looking for real estate developers in Dubai, Sharjah, UAE! In that case, EZhomes Properties can help you and with us, you will probably find some of the most exciting project that might suit your interest." />
                <meta name="keywords" content="Real Estate Developers, Dubai Real Estate Developers, Residential communities Developers, Residential developments, Real Estate Developers in Dubai, Off-Plan Properties Developers, Property Developers in Dubai, New property in Dubai, Luxury real state Dubai, Buy a Property, New properties in Dubai, Dubai Property investment" />
                <meta property="og:title" content="Real Estate Developers – Dubai, Sharjah, Abu Dhabi | International" />
                <meta property="og:description" content="Looking for real estate developers in Dubai, Sharjah, UAE! In that case, EZhomes Properties can help you and with us, you will probably find some of the most exciting project that might suit your interest." />
                <meta property="og:url" content="https://www.off-plan.ezhomesuae.com/developer/" />
                <meta property="og:type" content="https://www.ezhomesproperties.com/" />
            </Helmet>
            {/* Header */}
            <div className='header'>
                <div className="single-view-header flex items-center mb-5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ">
                                <div className='flex justify-center items-center'>
                                    <h3 className='font-poppins font-semibold text-white text-center'>Developer List</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* Options */}
                <ul className='list-unstyled p-0 d-md-none'>
                    <div className="row" id="project-tab">
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <Link to={"/"} className="btn btn-outline-primary rounded-30">
                                    <div>
                                        <i className="fa-solid fa-building block"></i>
                                        Projects
                                    </div>
                                </Link>
                            </li>
                        </div>
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <button className="btn btn-outline-primary rounded-30 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                    <div>
                                        <i className="fa-solid fa-building-user block"></i>
                                        Developer
                                    </div>
                                </button>
                            </li>
                        </div>
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <Link to={"/community"} className="btn btn-outline-primary rounded-30">
                                    <div>
                                        <i className="fa-solid fa-building-shield block"></i>
                                        Community
                                    </div>
                                </Link>
                            </li>
                        </div>
                    </div>
                </ul>
                <div className="text-center mb-lg-5 pb-2 d-none d-md-block">
                    <ul className="nav nav-pills mb-3 justify-content-center" id="project-tab">
                        <li className="nav-item" role="presentation">
                            <Link to={"/"} className="btn btn-outline-primary rounded-30">
                                <div>
                                    <i className="fa-solid fa-building block"></i>
                                    Projects
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="btn btn-outline-primary rounded-30 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                <div>
                                    <i className="fa-solid fa-building-user block"></i>
                                    Developer
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <Link to={"/community"} className="btn btn-outline-primary rounded-30">
                                <div>
                                    <i className="fa-solid fa-building-shield block"></i>
                                    Community
                                </div>
                            </Link>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <Link to={"/location"} className="btn btn-outline-primary rounded-30">
                                <div>
                                    <i className="fa-solid fa-location-dot block"></i>
                                    Location
                                </div>
                            </Link>
                        </li> */}
                    </ul>
                </div>
                {/* Developers */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h4 className=' font-poppins font-semibold text-black mb-3'>Developers <span>({sortedDeveloper?.length})</span></h4>
                            <div className="inner-filter border border-gray-700 rounded-lg p-4 mb-4">
                                <div className='row align-items-center justify-content-between'>
                                    <div className="col-4 col-lg-3 mb-2">
                                        <select id="projSortbyId" className="form-select form-control" value={sortOrder} onChange={handleSort}>
                                            <option value="newest">Newest</option>
                                            <option value="oldest">Oldest</option>
                                        </select>
                                    </div>
                                    <div className="col-8 col-lg-7 mb-2">
                                        <input className="form-control" list="projoption" id="txtprojoption" placeholder="Type to search..." value={searchInput}
                                            onChange={handleSearch} />
                                        <datalist id="projoption">
                                            {
                                                developerList?.map((ele, index) => <option value={ele?.name} key={index}></option>)
                                            }
                                        </datalist>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Developers Card */}
                <div className="container-fluid">
                    <div className="row">
                        {
                            isLoading ? <div className='flex justify-center'>
                                <div role="status">
                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div> : sortedDeveloper?.map((ele, index) => {
                                return (
                                    <div className="col-12 col-md-4 my-3" key={index}>
                                        <Link to={`/developer/${ele?.slug}`} className="developer-card block ">
                                            <div className="row">
                                                <div className="col-4 flex items-center">
                                                    <div className="media">
                                                        <img src={ele?.logo} alt="" className='img-fluid rounded' />
                                                    </div>
                                                </div>
                                                <div className="col-8 items-center flex">
                                                    <div className="content">
                                                        <h5 className='font-poppins font-bold text-darkslateblue'>{ele?.name}</h5>
                                                        <div className="flex justify-between items-center">
                                                            <span className='font-poppins font-medium text-black'>{dev?.map((devele) => {
                                                                if (devele?.developername == ele?.name) {
                                                                    return (
                                                                        <span>Projects: <b>{devele?.projects}</b></span>
                                                                    )
                                                                }
                                                            })}</span>
                                                        </div>
                                                        <div className="flex justify-between items-center">
                                                            <span className='font-poppins font-medium text-black'>Communities: <b>{ele?.commmunity}+</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Developer
