import Logo from "./brand_logo.png";
import Background from "./bg.jpg";
import Emaar from "./emaar.jpg"
// Social Media Icons
import Instagram from "./Social/instagram.webp";
import Facebook from "./Social/fb.webp";
import TwitterX from "./Social/tw.webp";
import LinkedIn from "./Social/in.webp";
import YouTube from "./Social/youtube.webp";

export const Images = {
    Logo,
    Instagram,
    Facebook,
    TwitterX,
    LinkedIn,
    YouTube,
    Background,
    Emaar
}