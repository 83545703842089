import React from 'react'
import { Link } from 'react-router-dom'
import { Images } from '../../../Assets/Images/Images'

const Footer = () => {
    return (
        <footer className=" py-5 mt-5 footer">
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-5 mb-4">
                        <div className="footer-container">
                            <div className="footer-logo mb-3">
                                <img src={Images?.Logo} alt="EZhomes Real Estate LLC" />
                            </div>
                            <div className="footer-content mb-3">
                                <p className='font-poppins text-white font-light'>Welcome to EZhomes Real Estate Broker LLC, a trusted and prominent leader in Dubai’s dynamic real estate market. With a steadfast commitment to excellence, integrity, and innovation, we have been dedicated to serving the real estate needs of our valued clients.</p>
                            </div>
                            <div className="footer-social flex justify-start items-center">
                                <a href="/">
                                    <img src={Images?.Facebook} className='me-2' alt="Official Account Of EZhomes Properties - Facebook" />
                                </a>
                                <a href="/">
                                    <img src={Images?.TwitterX} className='me-2' alt="Official Account Of EZhomes Properties - Twitter" />
                                </a>
                                <a href="/">
                                    <img src={Images?.LinkedIn} className='me-2' alt="Official Account Of EZhomes Properties - LinkedIn" />
                                </a>
                                <a href="/">
                                    <img src={Images?.YouTube} className='me-2' alt="Official Channel Of EZhomes Properties - Youtube" />
                                </a>
                                <a href="/">
                                    <img src={Images?.Instagram} className='me-2' alt="Official Account Of EZhomes Properties - Instagram" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mb-4">
                        <div className="footer_menu_title mb-3">
                            <h5 className='text-white font-poppins font-bold'>Contact Info</h5>
                        </div>
                        <div className="footer_menu_items mb-3">
                            <ul className='p-0'>
                                <li className='mb-3'>
                                    <div className="flex justify-start items-center text-white font-poppins font-normal">
                                        <span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                                        </svg>
                                        </span>
                                        <span>705, Opal Tower, Business Bay, Dubai</span>
                                    </div>
                                </li>
                                <li className='mb-3'>
                                    <div className="flex justify-start items-center text-white font-poppins font-normal">
                                        <span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                                        </svg>
                                        </span>
                                        <span>+971 55 563 0197</span>
                                    </div>
                                </li>
                                <li className='mb-3'>
                                    <div className="flex justify-start items-center text-white font-poppins font-normal">
                                        <span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                                        </svg>

                                        </span>
                                        <a href='mailto:info@ezhomesproperties.com' className='text-white'>info@ezhomesproperties.com</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-12 col-md-2 mb-4">
                        <div className="footer_menu_title mb-3">
                            <h5 className='text-white font-poppins font-bold'>Helpful Links</h5>
                        </div>
                        <div className="footer_menu_items">
                            <ul className='p-0'>
                                <li className='mb-3'><Link to={'/'} className='text-white text-decoration-none font-poppins font-normal'>Home</Link>
                                </li>
                                <li className='mb-3'>
                                    <a href='https://ezhomesproperties.com/about-us' className='text-white text-decoration-none font-poppins font-normal'>About Us</a>
                                </li>
                                <li className='mb-3'>
                                    <a href='https://ezhomesuae.com/blogs' className='text-white text-decoration-none font-poppins font-normal'>Blogs</a>
                                </li>
                                <li className='mb-3'>
                                    <a href='https://ezhomesproperties.com/contact-us' className='text-white text-decoration-none font-poppins font-normal'>Contact Us</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-12 col-md-2 mb-4">
                        <div className="footer_menu_title mb-3">
                            <h5 className='text-white font-poppins font-bold'>Explore Properties</h5>
                        </div>
                        <div className="footer_menu_items">
                            <ul className='p-0'>
                                <li className='mb-3'>
                                    <Link to={'/'} className='text-white text-decoration-none font-poppins font-normal'>By Properties</Link>
                                </li>
                                <li className='mb-3'>
                                    <Link to={'/developer'} className='text-white text-decoration-none font-poppins font-normal'>By Developer</Link>
                                </li>
                                <li className='mb-3'>
                                    <Link to={'/community'} className='text-white text-decoration-none font-poppins font-normal'>By Community</Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer
