import React from 'react'
import { Link } from 'react-router-dom'

const Option = () => {
    return (
        <div className="container">
            <div className="text-center mb-lg-5 pb-2">
                <ul className='list-unstyled p-0 d-md-none'>
                    <div className="row" id="project-tab">
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <button className="btn btn-outline-primary rounded-30 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                    <div>
                                        <i className="fa-solid fa-building block"></i>
                                        Projects
                                    </div>
                                </button>
                            </li>
                        </div>
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <Link to={"/developer"} className="btn btn-outline-primary rounded-30">
                                    <div>
                                        <i className="fa-solid fa-building-user block"></i>
                                        Developer
                                    </div>
                                </Link>

                            </li>
                        </div>
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <a href="/community" className="btn btn-outline-primary rounded-30">
                                    <div>
                                        <i className="fa-solid fa-building-shield block"></i>
                                        Community
                                    </div>
                                </a>
                            </li>
                        </div>
                    </div>
                </ul>
                <ul className="nav nav-pills mb-3 justify-content-center d-none d-md-flex" id="project-tab">
                    <li className="nav-item" role="presentation">
                        <button className="btn btn-outline-primary rounded-30 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                            <div>
                                <i className="fa-solid fa-building block"></i>
                                Projects
                            </div>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={"/developer"} className="btn btn-outline-primary rounded-30">
                            <div>
                                <i className="fa-solid fa-building-user block"></i>
                                Developer
                            </div>
                        </Link>

                    </li>
                    <li className="nav-item" role="presentation">
                        <a href="/community" className="btn btn-outline-primary rounded-30">
                            <div>
                                <i className="fa-solid fa-building-shield block"></i>
                                Community
                            </div>
                        </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <a href="/location" className="btn btn-outline-primary rounded-30">
                            <div>
                                <i className="fa-solid fa-location-dot block"></i>
                                Location
                            </div>
                        </a>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default Option
