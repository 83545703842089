import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';

const Add = () => {

    const [property, setProperty] = useState({
        title: "",
        thumbUrl: "",
        developer: "",
        developerLogo: "",
        type: "",
        bedrooms: "",
        sizes: "",
        finalURL: "",
        status: "",
        location: "",
    });

    const handleChange = (e) => {
        setProperty({ ...property, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://private-server-0dn5.onrender.com/properties', property);
            // setMessage('Property added successfully!');
            if(response?.status === 201) {
                toast.success("Listed Successfully");
                setProperty(({
                    title: "",
                    thumbUrl: "",
                    developer: "",
                    developerLogo: "",
                    type: "",
                    bedrooms: "",
                    sizes: "",
                    finalURL: "",
                    status: "",
                    location: "",
                    price:""
                }))
            } else{
                toast.error("Error Found")
            }
            
        } catch (error) {
            console.error('Error adding property:', error);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="row my-5">
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" onChange={handleChange} name='title' id="floatingTitle" placeholder="propertyName" value={property.title} />
                            <label for="floatingInput">Property Title</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" onChange={handleChange} name='thumbUrl' value={property?.thumbUrl} id="floatingImage" placeholder="imageUrl" />
                            <label for="floatingInput">Property Image</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingDev" onChange={handleChange} name='developer' value={property?.developer} placeholder="name@example.com" />
                            <label for="floatingInput">Developer</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingDevLogo" placeholder="devg" onChange={handleChange} name='developerLogo' value={property?.developerLogo} />
                            <label for="floatingInput">Developer Logo</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="name@type.com" onChange={handleChange} name='type' value={property?.type} />
                            <label for="floatingInput">Property Type</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="name@example.com" onChange={handleChange} name='bedrooms' value={property?.bedrooms} />
                            <label for="floatingInput">Bedrooms</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="name@size.com" onChange={handleChange} name='sizes' value={property?.sizes} />
                            <label for="floatingInput">Sizes</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="status@example.com" onChange={handleChange} name='status' value={property?.status} />
                            <label for="floatingInput">Status</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="location@example.com" onChange={handleChange} name='location' value={property?.location} />
                            <label for="floatingInput">Location</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="landing@example.com" onChange={handleChange} name='finalURL' value={property?.finalURL} />
                            <label for="floatingInput">Landing Page</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="landing@example.com" onChange={handleChange} name='price' value={property?.price} />
                            <label for="floatingInput">Price</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button className='bg-black px-5 py-3 text-white font-poppins w-100' onClick={handleSubmit}>Send For Verification</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Add
