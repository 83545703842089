import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';

const AddCom = () => {

    const [community, setCommunity] = useState({
        name: "",
        description: "",
        features: [
            "Marina & Yacht Club",
            "Kids Play Area",
            "Outdoor Pool",
            "Beach Access",
            "Hospital",
            "BBQ Areas",
            "Mosque",
            "Shopping Mall",
            "Restaurant and Cafe",
            "Weekend Markets",
            "Hotels"
        ],
        thumbUrl: "",
        slug: "",
    });

    const handleChange = (e) => {
        setCommunity({ ...community, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://off-plan.onrender.com/api/communities', community);
            // setMessage('Property added successfully!');
            if (response?.status === 201) {
                toast.success("Listed Successfully");
                setCommunity(({
                    name: "",
                    description: "",
                    features: [
                        "Marina & Yacht Club",
                        "Kids Play Area",
                        "Outdoor Pool",
                        "Beach Access",
                        "Hospital",
                        "BBQ Areas",
                        "Mosque",
                        "Shopping Mall",
                        "Restaurant and Cafe",
                        "Weekend Markets",
                        "Hotels"
                    ],
                    thumbUrl: "",
                    slug: "",
                }))
            } else {
                toast.error("Error Found")
            }

        } catch (error) {
            console.error('Error adding property:', error);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="row my-5">
                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" onChange={handleChange} name='name' id="floatingTitle" placeholder="propertyName" value={community.name} />
                            <label for="floatingInput">Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" onChange={handleChange} name='description' value={community?.description} id="floatingImage" placeholder="imageUrl" />
                            <label for="floatingInput">Description</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingDevLogo" placeholder="devg" onChange={handleChange} name='thumbUrl' value={community?.thumbUrl} />
                            <label for="floatingInput">Thumb URL</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingTitle" placeholder="name@type.com" onChange={handleChange} name='slug' value={community?.slug} />
                            <label for="floatingInput">Slug</label>
                        </div>

                    </div>
                    <div className="col-12">
                        <button className='bg-black px-5 py-3 text-white font-poppins w-100' onClick={handleSubmit}>Add Community</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCom
