import React, { useEffect, useState } from 'react'
import ComPro from '../../../Common/ComPro/ComPro'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet';

const SubCommunity = () => {

    const { id } = useParams();
    const [community, setCommunity] = useState({});
    const [loading, setLoading] = useState(true)


    const fetchCommunity = async () => {
        try {
            const response = await axios.get(`https://off-plan.onrender.com/api/communities/`);
            if (response?.status === 200) {
                setLoading(false);
                const Find = response?.data?.find((ele) => ele?.slug === id)
                setCommunity(Find);
                localStorage?.setItem('community', Find?.name)
            }
        } catch (error) {
            console.error("Error fetching the properties:", error);
        }
    };


    useEffect(() => {
        fetchCommunity();
    }, []);

    return (
        <div>
            <Helmet>
                <title>{`Properties for sale at ${community?.name}`}</title>
                <meta name="description" content={`${community?.description}`} />
                <meta name="keywords" content={`${community?.name}, Dubai Real Estate, Real Estate Dubai`} />
                <meta property="og:title" content={`Properties for sale at ${community?.name}`} />
                <meta property="og:description" content={`${community?.description}`} />
                <meta property="og:url" content={`https://www.off-plan.ezhomesuae.com/community/${id}`} />
                <meta property="og:type" content="https://www.ezhomesproperties.com/" />
            </Helmet>
            {/* Header */}
            <div className='header'>
                <div className="single-view-header flex items-center mb-5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ">
                                <div className='flex justify-center items-center'>
                                    <h3 className='font-poppins font-semibold text-white text-center'>{community?.name}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Content */}
            <div className="developer-content">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className='font-poppins font-bold'>About {community?.name}</h3>
                            <p className='font-poppins text-gray-500'>{community?.description}</p>
                        </div>
                        <div className="communities my-5">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className='font-poppins font-bold text-center'>Community Features</h3>
                                    <p className='text-center font-poppins text-gray-600'>This community aims to provide the maximum levels of comfort and luxury to the residents, thanks to advanced facilities and modern amenities that meet all their requirements effortlessly and smartly.</p>
                                </div>
                                {
                                    community?.features?.map((ele, index) => {
                                        return (
                                            <div className="col-3" key={index}>
                                                <div className="card shadow rounded-30 border-0 mb-4" style={{ borderRadius: "30px" }}>
                                                    <div className="card-body p-lg-4 text-center">
                                                        <h6 className="mt-2 font-poppins font-bold" style={{ fontSize: "16px" }}>{ele}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="communities-available my-5">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className='font-poppins font-bold text-center'>Available projects</h3>
                                    <p className='text-center font-poppins text-gray-600'>Take a look at the new off-plan developments in and around Dubai Take a look at some of the attractive investment offers.</p>
                                </div>
                                <ComPro communityData={community} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubCommunity
