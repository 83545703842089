import React, { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Images } from '../../../Assets/Images/Images';
import { Link } from 'react-router-dom';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const navigation = [
    { name: 'Home', href: 'https://ezhomesproperties.com/' },
    { name: 'Off-Plan', href: '/' },
    { name: 'Sale', href: 'https://ezhomesproperties.com/buy/' },
    { name: 'Rent', href: 'https://ezhomesproperties.com/rent/' },
    { name: 'About Us', href: 'https://ezhomesproperties.com/about-us/' },
    { name: 'Our Services', href: '/' },
    { name: 'About Us', href: 'https://ezhomesproperties.com/about-us/' },
]

const services = [
    { name: 'Property Management Services', href: 'https://ezhomesproperties.com/property-management-services/' },
    { name: 'First Time Buyer', href: 'https://ezhomesproperties.com/first-time-buyer/' },
    { name: 'Investment Advisory', href: 'https://ezhomesproperties.com/investment-advisory/' },
    { name: 'Free Property Valuation', href: 'https://ezhomesproperties.com/free-property-valuation-2/' },
    { name: 'Mortgage Advisory', href: 'https://ezhomesproperties.com/mortgage-advisory/' },
    { name: 'Mortgage Calculator', href: 'https://ezhomesproperties.com/mortgage-calculator/' },
    { name: 'Golden Visa', href: 'https://ezhomesproperties.com/golden-visa/' },
]

const Header = () => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div style={{ background: "#1C396C" }}>
            {/* <header className="absolute inset-x-0 top-0 z-50 mb-3" style={{ background: "#1C396C" }}> */}
            <div className="container">
                <nav aria-label="Global" className="flex items-center justify-between p-3 lg:px-8 ">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">EZhomes Real Estate LLC</span>
                            <img
                                alt=""
                                src={Images?.Logo}
                                className="h-8 w-auto"
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" style={{ color: "white" }} />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        <a href='https://ezhomesproperties.com/' className="text-sm font-semibold leading-6 text-white text-decoration-none uppercase font-poppins">
                            Home
                        </a>
                        <Link to={'/'} className="text-sm font-semibold leading-6 text-white text-decoration-none uppercase font-poppins">
                            Off-Plan
                        </Link>
                        <a href='https://ezhomesproperties.com/buy' className="text-sm font-semibold leading-6 text-white text-decoration-none uppercase font-poppins">
                            Sale
                        </a>
                        <a href='https://ezhomesproperties.com/rent' className="text-sm font-semibold leading-6 text-white text-decoration-none uppercase font-poppins">
                            Rent
                        </a>
                        <a href='https://ezhomesproperties.com/about-us' className="text-sm font-semibold leading-6 text-white text-decoration-none uppercase font-poppins">
                            About Us
                        </a>
                        
                        <Popover className="relative">
                            <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white uppercase font-poppins">
                                <span>Our Services</span>
                                <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                            </PopoverButton>

                            <PopoverPanel
                                transition
                                className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in" style={{ width: "315px" }}
                            >
                                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-2">
                                        {services.map((item) => (
                                            <div key={item?.name}>
                                                <Link to={item.href} className="font-semibold text-gray-900 px-2 py-2 text-decoration-none uppercase d-block">
                                                    {item.name}
                                                    {/* <span className="absolute inset-0" /> */}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </PopoverPanel>
                        </Popover>
                        <a href='https://ezhomesuae.com/blogs/' className="text-sm font-semibold leading-6 text-white text-decoration-none uppercase font-poppins">
                            Blogs
                        </a>
                        <a href='https://ezhomesproperties.com/contact' className="text-sm font-semibold leading-6 text-white text-decoration-none uppercase font-poppins">
                           Contact
                        </a>
                    </div>
                </nav>
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-50 bg-darkslateblue" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-darkslateblue px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">EZhomes Real Estate LLC</span>
                                <img
                                    alt=""
                                    src={Images?.Logo}
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-darkslateblue">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-slate-500 hover:text-black text-decoration-none"
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </div>

            {/* </header> */}
        </div>
    )
}

export default Header
