import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Properties = () => {

    const [propertiesList, setPropertiesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState(''); // Add a state for search input
    const [sortOrder, setSortOrder] = useState('newest');

    const fetchProperties = async () => {
        try {
            const response = await axios.get('https://off-plan.onrender.com/properties');
            if (response?.status === 200) {
                setIsLoading(false);
                const data = [...response?.data].sort(() => Math.random() - 0.5);
                setPropertiesList(data);
            } else {
                setIsLoading(true);
            }
        } catch (error) {
            console.error("Error fetching the properties:", error);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    const handleSearch = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSort = (e) => {
        setSortOrder(e.target.value);
    };

    const filteredProperties = propertiesList.filter((property) => {
        const title = property.title.toLowerCase();
        const searchValue = searchInput.toLowerCase();
        return title.includes(searchValue);
    });

    const sortedProperties = filteredProperties.sort((a, b) => {
        switch (sortOrder) {
            case 'newest':
                return new Date(b.createdAt) - new Date(a.createdAt);
            case 'oldest':
                return new Date(a.createdAt) - new Date(b.createdAt);
            case 'priceHighToLow':
                return b.price - a.price;
            case 'priceLowToHigh':
                return a.price - b.price;
            default:
                return 0;
        }
    });


    return (
        <div className='properties' id='properties'>

            <div className='properties' id='properties'>
                <div className="container-fluid">
                    <h4 className='font-poppins font-semibold text-black mb-3'>Projects <span>({sortedProperties?.length})</span></h4>
                    <div className="inner-filter border border-gray-700 rounded-lg p-4 mb-4">
                        <div className='row align-items-center justify-content-between'>
                            <div className="col-4 col-lg-3">
                                <select id="projSortbyId" className="form-select form-control" value={sortOrder} onChange={handleSort}>
                                    <option value="newest">Newest</option>
                                    <option value="oldest">Oldest</option>
                                    <option value="priceHighToLow">Price High To Low</option>
                                    <option value="priceLowToHigh">Price Low To High</option>
                                </select>
                            </div>
                            <div className="col-8 col-lg-7">
                                <input
                                    className="form-control"
                                    list="projoption"
                                    id="txtprojoption"
                                    placeholder="Type to search..."
                                    value={searchInput}
                                    onChange={handleSearch}
                                />
                                <datalist id='projoption'>
                                    {
                                        propertiesList?.map((ele, index) => <option value={ele?.title} key={index}></option>)
                                    }
                                </datalist>
                            </div>
                        </div>
                    </div>
                    <div className="projlistId">
                        {
                            isLoading ? <div className='flex justify-center'>
                                <div role="status">
                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">

                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div> : sortedProperties.map((ele, index) => {
                                return (
                                    <div className="card mb-4 pro_list" key={index}>
                                        <div className="row g-0">
                                            <div className="col-md-4 pro_img">
                                                <a href={ele?.finalURL}>
                                                    <img src={ele?.thumbUrl} className="img-fluid rounded-start" alt={ele?.name} /><span>{ele?.status}</span></a>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="title">
                                                            <span className="d-block price font-poppins"><i className="fa-solid fa-circle-arrow-up me-1"></i>{ele?.price ? `AED ${ele?.price}` : "CALL US"}</span>
                                                            <h5 className="card-title">
                                                                <a href={ele?.finalURL} className='font-poppins text-decoration-none text-black font-semibold'>{ele?.title}</a>
                                                            </h5>
                                                            <div className="location font-poppins "><i className="fa-solid fa-location-dot font-bold text-darkslateblue fs-5 me-2"></i>{ele?.location}</div>
                                                        </div>
                                                        <div className="company__logo">
                                                            <img className="img-fluid" src={ele?.developerLogo} alt={ele?.developerName} style={{ width: "auto", height: "60px" }} />
                                                        </div>
                                                    </div>
                                                    <div className="bg-light rounded-6 summry">
                                                        <div className="accordion" id="accordionPanelsStayOpenExample2">
                                                            <div className="accordion-item border-0">
                                                                <h2 className="accordion-header" id="panelsStayOpen-heading1927">
                                                                    <div className="head">
                                                                        <ul className='d-block d-md-flex'>
                                                                            <li className='mb-2 mb-m-0'>
                                                                                <i className="fa fa-building"></i>
                                                                                <div>
                                                                                    <span className="small">Property Type</span>
                                                                                    <p className='mb-0'>{ele?.type}</p>
                                                                                </div>
                                                                            </li>
                                                                            <li className='mb-2 mb-m-0'>
                                                                                <i className="fa fa-bed"></i>
                                                                                <div>
                                                                                    <span className="small">Bedroom</span>
                                                                                    <p className='mb-0'>{ele?.bedrooms}</p>
                                                                                </div>
                                                                            </li>
                                                                            <li className='mb-2 mb-m-0'>
                                                                                <i className="fa fa-area-chart"></i>
                                                                                <div>
                                                                                    <span className="small">Size</span>
                                                                                    <p className='mb-0'>{ele?.sizes}</p>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </h2>
                                                                <div id="panelsStayOpen-collapse1927" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingNine">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btns d-none d-md-block">
                                                        <div className="flex justify-start items-center">
                                                            <a href={ele?.finalURL} className='probtn text-decoration-none font-poppins'>
                                                                % Down Payment
                                                            </a>
                                                            <a href={ele?.finalURL} className='probtn text-decoration-none font-poppins'>
                                                                <i className="fa-solid fa-money-bill text-white"></i> Easy Payments
                                                            </a>
                                                            <a href={ele?.finalURL} className='probtn text-decoration-none font-poppins'>
                                                                <i className="fa-solid fa-calendar-days text-white"></i> Handover
                                                            </a>
                                                            <a href={ele?.finalURL} className='probtn text-decoration-none font-poppins'>
                                                                <i className="fa-solid fa-cloud-arrow-down text-white"></i> Download Brochure
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="cta-btn d-block d-md-none mt-3">
                                                        <Link to={ele?.finalURL} className='bg-darkslateblue block text-center text-decoration-none text-white w-100 rounded-xl py-2 font-poppins font-medium'>DOWNLOAD BROCHURE</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Properties
