import axios from 'axios';
import React, { useEffect, useState } from 'react'

const ProCard = ({developer}) => {

    const [propertiesList, setPropertiesList] = useState([]);

    const fetchProperties = async () => {
        
        try {
            const response = await axios.get('https://off-plan.onrender.com/properties');
            if (response?.status === 200) {
                const data = response?.data?.filter((ele) => ele?.developer === localStorage?.getItem('developer'))
                setPropertiesList(data);
            }
        } catch (error) {
            console.error("Error fetching the properties:", error);
        }
    };


    useEffect(() => {
        fetchProperties();
    }, []);

    return (
        <div className='row'>
            {
                propertiesList?.map((ele, index) => {
                    return (
                        <div className="col-12 col-md-4" key={index}>
                            <div className="single-featured-item wow animate__animated animate__fadeInUp delay-0-2s animated animated" style={{ visibility: "visible", animationName: "fadeInUp" }}>
                                <a href={ele?.finalURL} className="no-anchor">
                                    <div className="featured-img">
                                        <img src={ele?.thumbUrl} alt="Address Residences at Dubai Hills Estate" />
                                        <span className='font-outfit'>{ele?.status}</span>
                                    </div>
                                    <div className="featured-content"><div className="d-flex justify-content-between">
                                        <h3 className='font-poppins text-black font-bold mb-2'>{ele?.title}</h3>
                                    </div>
                                        <p className='font-poppins flex justify-start items-center mb-1'>
                                            <span><i className="fa-solid fa-location-dot"></i></span><span>{ele?.location}</span>
                                        </p>
                                        <p className='font-poppins flex justify-start items-center mb-1'>
                                            <span><i className="fa-solid fa-bed"></i></span><span>{ele?.bedrooms}</span>
                                        </p>
                                        <p className='font-poppins flex justify-start items-center mb-1'>
                                            <span><i className="fa-solid fa-ruler-combined"></i></span><span>{ele?.sizes}</span>
                                        </p>
                                        <hr />
                                        <span className="agent-user">
                                            <h3 className="price font-poppins font-semibold text-darkslateblue"><i className="fa-solid fa-circle-arrow-up me-1"></i>{ele?.price ? `AED ${ele?.price}` : "CALL US"}</h3>
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default ProCard
