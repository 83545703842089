import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Community = () => {

    const [communities, setCommunities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState(''); // Add a state for search input
    const [sortOrder, setSortOrder] = useState('newest');

    const navigate = useNavigate()

    const fetchCommunities = async () => {
        try {
            const response = await axios.get('https://off-plan.onrender.com/api/communities');
            if (response?.status === 200) {
                setIsLoading(false)
                const data = [...response?.data].sort(() => Math.random() - 0.5);
                setCommunities(data);
            } else {
                setIsLoading(true)
            }

        } catch (error) {
            console.error("Error fetching the properties:", error);
        }
    };

    const handleSearch = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSort = (e) => {
        setSortOrder(e.target.value);
    };

    const filteredDeveloper = communities.filter((property) => {
        const title = property.name.toLowerCase();
        const searchValue = searchInput.toLowerCase();
        return title.includes(searchValue);
    });

    const sortedCommunities = filteredDeveloper.sort((a, b) => {
        switch (sortOrder) {
            case 'newest':
                return new Date(b.createdAt) - new Date(a.createdAt);
            case 'oldest':
                return new Date(a.createdAt) - new Date(b.createdAt);
            default:
                return 0;
        }
    });

    useEffect(() => {
        fetchCommunities();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Communities and Township in Dubai, UAE - Developers and Projects</title>
                <meta name="description" content="Dubai is a massive world-renowned entertainment complex that will be home to many attractions that will draw visitors and residents to the communities in Dubai. With off-plan projects in Dubai Communities continuously being developed, it is no time when it will soon reach its goals of being the center of all it aims to be." />
                <meta name="keywords" content="Communities, Township, Communities and Township in Dubai, Developers and Projects, All Communities in Dubai, UAE Communities, Dubai Communities, Integrated Township, Areas in Dubai, Residential communities, Communities developments, Real Estate Communities in Dubai, Off-Plan Community, New property in Dubai Communities, Buy a Property in Dubai Community, New properties in Dubai Community, Residential communities Dubai, Residential communities UAE, Communities in Dubai Developers, Communities in Dubai Projects" />
                <meta property="og:title" content="Communities and Township in Dubai, UAE - Developers and Projects" />
                <meta property="og:description" content="Dubai is a massive world-renowned entertainment complex that will be home to many attractions that will draw visitors and residents to the communities in Dubai. With off-plan projects in Dubai Communities continuously being developed, it is no time when it will soon reach its goals of being the center of all it aims to be." />
                <meta property="og:url" content="https://www.off-plan.ezhomesuae.com/community/" />
                <meta property="og:type" content="https://www.ezhomesproperties.com/" />
            </Helmet>
            {/* Header */}
            <div className='header'>
                <div className="single-view-header flex items-center mb-5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ">
                                <div className='flex justify-center items-center'>
                                    <h3 className='font-poppins font-semibold text-white text-center'>Community List</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* Options */}
                <ul className='list-unstyled p-0 d-md-none'>
                    <div className="row" id="project-tab">
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <Link to={"/"} className="btn btn-outline-primary rounded-30">
                                    <div>
                                        <i className="fa-solid fa-building block"></i>
                                        Projects
                                    </div>
                                </Link>
                            </li>
                        </div>
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <Link to={"/developer"} className="btn btn-outline-primary rounded-30">
                                    <div>
                                        <i className="fa-solid fa-building-user block"></i>
                                        Developer
                                    </div>
                                </Link>
                            </li>
                        </div>
                        <div className="col-4">
                            <li className="nav-item" role="presentation">
                                <button className="btn btn-outline-primary rounded-30 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                    <div>
                                        <i className="fa-solid fa-building-shield block"></i>
                                        Community
                                    </div>
                                </button>

                            </li>
                        </div>
                    </div>
                </ul>
                <div className="text-center mb-lg-5 pb-2 d-none d-md-block">
                    <ul className="nav nav-pills mb-3 justify-content-center" id="project-tab">
                        <li className="nav-item" role="presentation">
                            <Link to={"/"} className="btn btn-outline-primary rounded-30">
                                <div>
                                    <i className="fa-solid fa-building block"></i>
                                    Projects
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                            <Link to={"/developer"} className="btn btn-outline-primary rounded-30">
                                <div>
                                    <i className="fa-solid fa-building-user block"></i>
                                    Developer
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="btn btn-outline-primary rounded-30 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                <div>
                                    <i className="fa-solid fa-building-shield block"></i>
                                    Community
                                </div>
                            </button>

                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <Link to={"/location"} className="btn btn-outline-primary rounded-30">
                                <div>
                                    <i className="fa-solid fa-location-dot block"></i>
                                    Location
                                </div>
                            </Link>
                        </li> */}
                    </ul>
                </div>
                {/* Communities */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h4 className=' font-poppins font-semibold text-black mb-3'>Communities  <span>({sortedCommunities?.length})</span></h4>
                            <div className="inner-filter border border-gray-700 rounded-lg p-4 mb-4">
                                <div className='row align-items-center justify-content-between'>
                                    <div className="col-6 col-lg-3 mb-2">
                                        <select id="projSortbyId" className="form-select form-control" onchange="fnProjlistonSearch();">
                                            <option selected="" value="0">City</option>
                                            <option value="1">Abu Dhabi</option>
                                            <option value="2">Ajman</option>
                                            <option value="3">Al Ain</option>
                                        </select>
                                    </div>
                                    <div className="col-6 col-lg-3 mb-2">
                                        <select id="projSortbyId" className="form-select form-control" onchange="fnProjlistonSearch();">
                                            <option selected="" value="0">Communities</option>
                                            <option value="1">Jumeriah Village</option>
                                            <option value="2">Business Bay</option>
                                            <option value="3">Dubailand</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                    <input className="form-control" list="projoption" id="txtprojoption" placeholder="Type to search..." value={searchInput}
                                            onChange={handleSearch} />
                                        <datalist id='projoption' onChange={handleSearch}>
                                            {
                                                communities?.map((ele) => <option value={ele?.name} key={ele?.name}></option>)
                                            }
                                        </datalist>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Community Card */}
                <div className="container-fluid">
                    <div className="row">
                        {
                            isLoading ? <div className='flex justify-center'>
                                <div role="status">
                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div> : sortedCommunities?.map((ele, index) => {
                                return (
                                    <div className="col-12 col-md-4 mb-3" key={index}>
                                        <Link to={`/community/${ele?.slug}`} className="community-card block text-start">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="media" style={{ height: "220px" }}>
                                                        <img src={ele?.thumbUrl} alt="" className='img-fluid rounded' style={{ height: "260px" }} />
                                                    </div>
                                                    <div className="content mt-2">
                                                        <h5 className='font-poppins font-bold text-darkslateblue'>{ele?.name}</h5>
                                                        <p className="card-text text2 mb-0 font-poppins text-gray-600">{ele?.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>)
                            })
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Community
